@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.previewItem {
  font-size: 13px;
  color: $color-blue-gray-900;
  width: 300px;
  flex: 300px 0 0;
  min-width: 300px;
  margin: 6px;

  border: 1px solid rgba(0, 0, 0, 0.09);
  background: #fff;

  // Animate nicely
  opacity: 0;
  transform: translate3d(0, -0.25rem, 0);

  animation: subtle-drop 0.4s ease-in-out;
  animation-fill-mode: forwards;

  @for $i from 1 through 4 {
    &:nth-child(#{$i}) {
      animation-delay: ($i - 1) * (200ms);
    }
  }

  &:nth-child(n + 4) {
    animation-delay: (4 * 200ms);
  }

  @media (max-width: 768px) {
    & + & {
      display: none;
    }
  }
}

.image {
  margin: 0;
  width: 100%;
  max-width: none;
}

.content {
  padding: 1rem;

  .hasFooter & {
    padding-bottom: 0;
  }
}

.title {
  font-weight: 600;
  font-size: calc(16 / 16 * 1rem);
  line-height: 1.2;
  margin-bottom: 0.75rem;
}

.text {
  color: $color-blue-gray-900;
  font-size: calc(14 / 16 * 1rem);
  max-height: (18px * 4);
  line-height: 18px;
  overflow: hidden;

  a {
    text-decoration: underline;
    color: $color-blue-gray-900;
  }
}

.readMoreLink {
  display: inline-block;
  margin-top: 0.5rem;
  font-weight: 500;
  color: $color-blue-gray-900;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  font-size: 0.9em;
  line-height: 1.2;
  min-height: 3rem;
  padding: 1rem;
}

.author {
  font-weight: 600;
  margin-bottom: 0.15em;
}

.timestamp {
  color: $color-blue-gray-500;
}
