@import 'styles/variables.scss';

.sidebar {
  padding: 1.5rem 1rem;
  text-shadow: 0 1px 0 rgba(#000, 0.1);
  overflow: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  > * + * {
    margin-top: 1rem;
  }
}
