@import 'styles/variables';

.icon {
  color: $color-blue-gray-800;
  height: 0.7rem;
  margin: 0 0.25em;
  position: relative;
  top: 1px;
  width: 0.7rem;
}
