@import 'styles/animations.scss';
@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.customSecondaryAction {
  @media (min-width: 1024px) {
    display: flex;
  }
}

.customSecondaryButton {
  border: none;
  background: linear-gradient(to bottom, #0ba4ef, #026dd9);
  border: none;
  border-radius: 2em;
  box-shadow: $box-shadow-1;

  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: calc(12 / 16 * 1rem);

  padding: 0.6em 1.25em;
  text-shadow: 0 -1px 0 rgba(#000, 0.1);

  display: block;
  width: 100%;
  border-radius: 30px;
  font-weight: 600;
  margin: 0 0 0.5rem;

  margin-bottom: 0.5em;

  opacity: 0;
  transform: translate3d(0, -0.25rem, 0);
  animation-name: subtle-drop;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  @media (min-width: 1024px) {
    width: auto;
  }

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }
}
