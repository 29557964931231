.dots {
  display: inline-block;
  min-width: 0.85rem;

  &:after {
    animation: loadingDots 3s linear infinite;
    animation-delay: 2s;
    display: inline-block;
    content: '...';
  }
}

@keyframes loadingDots {
  0% {
    content: '...';
  }
  25% {
    content: '';
  }
  50% {
    content: '.';
  }
  75% {
    content: '..';
  }
}
