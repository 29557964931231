@import 'styles/variables.scss';

.panel {
  position: fixed;
  top: $header-height;
  bottom: 0;
  left: 0;
  width: 20rem;
  background: #fff;
  border-right: 2px solid $color-pink;
  z-index: 9;

  font-size: 0.7em;
}
