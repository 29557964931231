@import 'styles/animations.scss';
@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.userMessage {
  align-items: flex-start;
  animation-name: fadeInFromAbove;
  animation-duration: 0.6s;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.userMessageAvatar {
  border: 0.2rem solid #fff;
  border-radius: 100%;
  box-shadow: $box-shadow-1;
  height: 2.5rem;
  margin-left: 0.25rem;
  margin-top: 0;
  min-width: 2.5rem;
  overflow: hidden;
  flex: 2.5rem 0 0;

  @include breakpoint($breakpoint-medium) {
    border: 0.25rem solid #fff;
    margin-right: 0.5rem;
    height: 2.75rem;
    min-width: 2.75rem;
  }

  .userMessage + .userMessage & {
    display: none;
  }
}

.userMessageContents {
  font-size: calc(15 / 16 * 1rem);
  margin-right: 0.5rem;
  max-width: 90%;
  text-align: right;

  @include breakpoint($breakpoint-medium) {
    max-width: 85%;
  }

  > * + * {
    margin-top: 0.75rem;
  }
}

.userMessageContent {
  background: $color-blue-gray-50;
  border-radius: 1em;
  display: inline-block;
  line-height: 1.5;
  margin-left: 0.5rem;
  padding: 1em 1.5em;
}
