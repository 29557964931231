@import 'styles/variables.scss';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.logo {
  color: $brand-primary;
  margin-top: 1rem;
  width: 10rem;
}
