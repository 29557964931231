@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.appContainer {
  padding-top: $header-height-mobile;
  overflow-x: hidden;

  @include breakpoint($breakpoint-medium) {
    padding-top: $header-height;
  }
}

.content {
  position: relative;
  z-index: 1;
}
