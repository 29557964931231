// Brand colors 2024
$color-primary: #1D4ED8;
$color-primary-lighter: #E1EEFE;
$color-primary-light: #71AAF4;
$color-primary-dark: #0047A4;
$color-primary-darker:  #002351;

$color-neutral: #667B99;
$color-neutral-lighter: #F1F4F9;
$color-neutral-light: #C2CAD6;
$color-neutral-dark: #424F62;
$color-neutral-darker:  #1F2937;

$color-secondary: #07A672;
$color-secondary-lighter: #DEF7EF;
$color-secondary-light: #88DDC1;
$color-secondary-dark: #0E8160;
$color-secondary-darker: #00513E;

$color-error: #DB273F;
$color-error-lighter: #FFEBEB;
$color-error-light: #F5A3AE;
$color-error-dark: #B8142A;
$color-error-darker: #660002;

$color-purple: #A855F7;
$color-purple-lighter: #F1E0FF;
$color-purple-light: #B775F0;
$color-purple-dark: #6E14B8;
$color-purple-darker: #3A0066;

$color-warning: #F8AD46;
$color-warning-lighter: #FEF6EB;
$color-warning-light: #FABE6B;
$color-warning-dark: #945805;
$color-warning-darker: #4F2F03;

$color-pink:  #DB2777;
$color-pink-lighter: #FFE0EF;
$color-pink-light: #F075B0;
$color-pink-dark: #B81463;
$color-pink-darker: #66003C;

// assigning to existing variables
$brand-primary: $color-primary;

$color-blue: $color-primary;
$color-green: $color-secondary;

$color-blue-gray-15: $color-neutral-lighter;
$color-blue-gray-25: $color-neutral-lighter;
$color-blue-gray-50: $color-neutral-lighter;
$color-blue-gray-100: $color-neutral-lighter;
$color-blue-gray-200: $color-neutral-light;
$color-blue-gray-300: $color-neutral-light;
$color-blue-gray-400: $color-neutral;
$color-blue-gray-500: $color-neutral;
$color-blue-gray-600: $color-neutral;
$color-blue-gray-700: $color-neutral-dark;
$color-blue-gray-800: $color-neutral-dark;
$color-blue-gray-900: $color-neutral-darker;

@font-face {
  font-family: 'Figtree';
  font-weight: 100 900;
  font-style: normal;
  src: url('~@flockler/fl-assets/fonts/figtree-variable-wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Figtree';
  font-weight: 100 900;
  font-style: italic;
  src: url('~@flockler/fl-assets/fonts/figtree-italic-variable-wght.ttf') format('truetype');
}

// # Typography
$font-family-sans-serif: 'Figtree', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Noto Color Emoji',
  'Segoe UI Symbol';

$font-size: 16px;
$font-size-mobile: 14px;

// # Colors
$color-lime-1: $color-secondary;
$color-lime-2: $color-secondary-light;

// # Elements
$header-height: 5.3rem;
$header-height-mobile: 4rem;
$content-container-width: 60rem;

// # Input
$input-height: 3rem;
$input-border-radius: 0.25rem;
$input-font-size: calc(15 / 16 * 1rem);

// # Breakpoints
$breakpoint-mobile: 420px;
$breakpoint-small: 768px;
$breakpoint-medium: 1024px;

// # Shadows
$box-shadow-1: 0 1px 2px rgba(0, 0, 0, 0.06), 0 2px 4px rgba(0, 0, 0, 0.04),
  0 5px 14px rgba(0, 0, 0, 0.02);
$box-shadow-2: 0 1px 3px rgba(0, 0, 0, 0.03), 0 2px 6px rgba(0, 0, 0, 0.06),
  0 6px 16px rgba(0, 0, 0, 0.04);
