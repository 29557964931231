@import 'styles/animations.scss';
@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.columns {
  display: flex;

  .textColumn {
    @include breakpoint($breakpoint-small, 'min') {
      flex: 55% 0 0;
      width: 55%;
      padding-right: 1rem;
    }
  }
}

@keyframes change-slide-1 {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes change-slide-2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes change-slide-3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes change-slide-4 {
  0% {
    opacity: 1;
  }

  1% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.embedsMockups {
  display: block;

  flex: 45% 0 0;
  width: 45%;

  position: relative;
  right: -0.7rem;
  margin-top: -0.25rem;
  opacity: 0;
  transform: translate3d(0, -0.25rem, 0);

  animation: subtle-drop 0.5s;
  animation-fill-mode: forwards;
  min-height: 200px;
  overflow: hidden;

  border: 1px solid $color-blue-gray-50;
  border-radius: 10px;
  box-shadow: 0 3px 6px $color-blue-gray-25;
  background: #fafafa;

  &:before {
    content: '';
    display: block;
    background: $color-blue-gray-50;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 15px;
    z-index: 9;
  }

  &:after {
    content: '•••';
    position: absolute;
    top: -2px;
    left: 0.4rem;
    font-size: 12px;
    line-height: 20px;
    color: $color-blue-gray-200;
    z-index: 10;
  }

  @include breakpoint($breakpoint-medium) {
    min-height: 225px;
    right: -0.9rem;
  }

  @include breakpoint($breakpoint-small, 'max') {
    display: none;
  }

  img {
    width: 100%;
    display: block;
    position: absolute;
    right: 0;
    top: 15px;

    animation-duration: 14s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;

    &:nth-child(1) {
      animation-name: change-slide-1;
    }
    &:nth-child(2) {
      animation-name: change-slide-2;
    }
    &:nth-child(3) {
      animation-name: change-slide-3;
    }
    &:nth-child(4) {
      animation-name: change-slide-4;
    }
  }
}

.hideMobile {
  @include breakpoint($breakpoint-small, 'max') {
    display: none;
  }
}

.reviewFormPreviewText {
  max-width: 450px;
}

.reviewFormPreview {
  margin-top: 1rem;
  border: 1px dashed rgb(203, 213, 225);
  padding: 1rem 1rem 0.75rem;
  border-radius: 0.5rem;
  max-width: 450px;

  @include breakpoint($breakpoint-small, 'max') {
    width: calc(100vw - 90px);
  }
}

.reviewFormEmbed {
  background: $color-blue-gray-50;
  margin-top: 1rem;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  position: relative;
  overflow: hidden;
  max-width: 550px;
  white-space: nowrap;
  min-height: 3.7rem;

  @include breakpoint($breakpoint-small, 'max') {
    width: calc(100vw - 90px);
  }
}

.reviewFormEmbedCopy {
  position: absolute;
  right: 0;
  top: 9px;
  bottom: 0;
  padding: 0.5rem 0.5rem 0.5rem 4rem;
  background: linear-gradient(to left, $color-blue-gray-50 75%, rgba($color-blue-gray-50, 0) 100%);
}
