@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.card {
  box-shadow: $box-shadow-1;
  background: #fff;
  border-radius: 1.5rem 0.5rem 1.5rem 1.5rem;
  padding: 1.5em;
  min-width: calc(100vw - 4.5rem);
  text-align: left;

  @include breakpoint(30rem) {
    width: auto;
    min-width: 28rem;
  }
}

.title {
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.07em;
  margin-bottom: 0.8rem;
  padding-left: 0.4rem;
  text-transform: uppercase;
}
