@import 'styles/variables.scss';

$avatar-bg-color: $color-blue-gray-50;

.avatar {
  display: inline-block;
  position: relative;
  background-color: $avatar-bg-color;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.imageAvatar {
  height: 100%;
  max-width: 100%;
  width: 100%;
  max-height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  display: inline-block;
  border-radius: inherit;
}

.textAvatar {
  background-color: inherit;
  border-radius: inherit;
  color: #aaa;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;

  position: relative;
  z-index: 1;

  width: inherit;
  height: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
}
