@import 'styles/animations.scss';
@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.row {
  display: block;
}

.column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

// Service selecct
.serviceButton {
  display: inline-block;
  position: relative;

  & + & {
    margin: 0 0 0.6em 0.5em;
  }

  @include breakpoint($breakpoint-mobile) {
    & + & {
      margin: 0 0 0.5em 0.6em;
    }
  }
}

.label {
  font-size: 0.7rem;
  color: $color-blue-gray-800;
  font-weight: 500;
  display: block;
  margin-top: 0.2rem;
  text-align: center;

  @include breakpoint($breakpoint-mobile) {
    font-size: 0.85rem;
  }

  .answered & {
    @include breakpoint($breakpoint-small, 'max') {
      display: none;
    }
  }

  .selected &,
  .serviceButton:hover & {
    color: $color-blue-gray-900;
  }

  .serviceSelectButton + & {
    position: absolute;
    background: $color-blue-gray-800;
    color: $color-blue-gray-15 !important;
    box-shadow: $box-shadow-2;
    padding: 3px 6px;
    border-radius: 5px;
    bottom: -1.7rem;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    z-index: 9;
    font-weight: 600;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
    visibility: hidden;
  }

  @include breakpoint($breakpoint-mobile) {
    .serviceSelectButton:hover + & {
      opacity: 0.95;
      visibility: visible;
      transform: translate3d(-50%, -0.25rem, 0);
    }
  }
}

.actionButton--instagram {
  color: pink;
}

.serviceSelectWrap {
  @include breakpoint($breakpoint-medium) {
    max-width: 523px;
    float: right;
  }
}

.serviceSelectButton {
  background: #fff;
  box-shadow: $box-shadow-1;

  &:hover {
    box-shadow: $box-shadow-2;
    filter: none !important;
    -webkit-filter: none !important;
  }

  .selected & {
    background: #fff;
  }

  border-radius: 10px;
  width: 52px;
  height: 52px;
  padding: 0;
  text-align: center;

  @include breakpoint($breakpoint-medium) {
    width: 80px;
    height: 80px;
    transform: translate3d(0, 0, 0);
    transition: transform 0.2s ease-in-out;
    will-change: transform;

    &:hover {
      transform: translate3d(0, -0.25rem, 0);
    }
  }
}

.icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  img {
    height: auto;
    margin: 0 auto;
    width: 1.75rem;

    .serviceButton--YouTube & {
      width: 1.9rem;
    }

    @include breakpoint($breakpoint-medium) {
      display: block;
      width: 53%;

      .serviceButton--Reviews & {
        width: 56%;
      }

      .serviceButton--YouTube &,
      .serviceButton--Twitter & {
        width: 61%;
      }
    }
  }
}

.actionsTitle {
  color: $color-blue-gray-500;
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.07em;
  margin-bottom: 0.8rem;
  margin-top: 0.7rem;
  padding-left: 2rem;
  text-transform: uppercase;
  width: 100%;
  text-align: left;

  @include breakpoint($breakpoint-small) {
    padding-left: 1.2rem;
  }
}

// # option
.option {
  position: relative;
  margin-left: 2rem;
  width: calc(100% - 2rem);

  @include breakpoint($breakpoint-small) {
    width: 100%;
    margin-left: 0;
  }

  .checkmark {
    position: absolute;
    background: $color-green;
    padding: 0;
    border-radius: 50%;
    left: -28px;
    top: 50%;
    margin-top: -12px;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    color: #fff;
    display: none;

    @include breakpoint($breakpoint-small) {
      left: -53px;
      top: 50%;
      margin-top: -17px;
      height: 30px;
      width: 30px;
    }
  }

  &.selected .checkmark {
    animation: scale-fade-in 0.25s;
    display: flex;
  }
}

.optionActionButton {
  background: #fff;
  box-shadow: $box-shadow-1;
  border-radius: 1.5rem 0.5rem 1.5rem 1.5rem;

  display: flex;
  align-items: center;
  text-shadow: none;
  color: $color-blue-gray-900;
  align-items: center;
  text-align: left;
  padding: 0.9rem 2rem 0.9rem 1.2rem;
  width: 100%;

  &:hover {
    box-shadow: $box-shadow-2;
    filter: none !important;
    -webkit-filter: none !important;
  }

  .answered:not(.selected) & {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .selected & {
    background: #fff;
    box-shadow: $box-shadow-1;
    color: $color-blue-gray-900;
  }
}

.optionIcon {
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  flex: 2rem 0 0;
  margin-right: 1rem;

  color: #4a86d6;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.optionTitle {
  display: block;
  font-weight: 600;
  font-size: calc(15 / 16 * 1rem);
  margin-bottom: 0.25rem;
  line-height: 1.25;

  @include breakpoint($breakpoint-small) {
    margin-bottom: 0.1rem;
  }
}

.optionDescription {
  font-size: calc(14 / 16 * 1rem);
  font-weight: 400;
  opacity: 0.9;
}

.actionButton {
  position: relative;
}

.actionButtonLabel {
  display: none;
  position: absolute;
  background: $color-blue-gray-800;
  box-shadow: $box-shadow-2;
  top: calc(100% + 6px);
  padding: 4px 5px;
  width: 500px;
  border-radius: 5px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.2s;
  font-size: 0.75rem;

  .actionButton:focus &,
  .actionButton:hover & {
    opacity: 1;
  }

  @include breakpoint($breakpoint-small) {
    display: block;
  }
}
