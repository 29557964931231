@import 'styles/animations.scss';
@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.botMessage {
  animation-name: fadeInFromAbove;
  animation-duration: 0.6s;
  align-items: flex-start;
  display: flex;
  max-width: 85%;
  margin-bottom: 0.75rem;

  @include breakpoint($breakpoint-medium) {
    max-width: 80%;
  }

  &.disableAnimation {
    animation: none;
  }
}

.botMessageAvatar {
  border: 0.2rem solid #fff;
  border-radius: 100%;
  box-shadow: $box-shadow-1;
  border-radius: 100%;
  height: 2.5rem;
  margin-right: 0.25rem;
  margin-top: 0;
  width: 2.5rem;
  min-width: 2.5rem;
  overflow: hidden;

  &.hidden {
    opacity: 0;
  }

  @include breakpoint($breakpoint-medium) {
    border: 0.25rem solid #fff;
    margin-right: 0.5rem;
    height: 2.75rem;
    width: 2.75rem;
    min-width: 2.75rem;
  }
}

.botMessageContents {
  max-width: 85%;

  @include breakpoint($breakpoint-medium) {
    max-width: 80%;
  }

  > * + * {
    margin-top: 0.75rem;
  }
}
