@mixin breakpoint($point, $type: 'min') {
  $modifier: 0;

  @if $type == 'max' {
    $modifier: 1;
  }

  @media (#{$type}-width: $point - $modifier) {
    @content;
  }
}
