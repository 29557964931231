@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.conversation {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 0 2rem;
  width: $content-container-width;

  transition: transform 0.25s cubic-bezier(0.77, 0, 0.175, 1);

  &.dodgeMessenger {
    @media (min-width: 80rem) and (max-width: 108rem) {
      transform: translate3d(-11rem, 0, 0);
    }
  }

  // Add extra space for chat button
  @include breakpoint($breakpoint-medium, 'max') {
    padding-bottom: 6rem;
  }
}

.conversationWrapper {
  padding: 2rem 0.5rem 0;
  @include breakpoint($breakpoint-medium) {
    padding-top: 6rem;
  }
}

.conversationPart {
  transition: opacity 0.6s;
}
