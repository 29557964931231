@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.buttonWrap {
  margin: 0;
}

.button {
  appearance: none;
  background: transparent;
  border: none;
  color: $color-blue-gray-500;
  cursor: pointer;
  font-size: calc(15 / 16 * 1rem);
  margin-top: 0.5rem;
  text-decoration: underline;
  transition: color 0.3s ease-in-out;
  user-select: none;
  margin-bottom: .2rem;

  @include breakpoint($breakpoint-medium) {
    font-size: calc(13 / 16 * 1rem);
  }

  &:hover {
    color: $color-blue-gray-900;
  }
}
