@import 'styles/reset';
@import 'styles/typography';
@import 'styles/variables';
@import 'styles/mixins';

html {
  background: $color-blue-gray-15;
  color: $color-blue-gray-900;
  box-sizing: border-box;
  font-family: $font-family-sans-serif;
  font-size: $font-size-mobile;
  -webkit-font-smoothing: antialiased;

  @include breakpoint($breakpoint-medium) {
    font-size: $font-size;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.hidden {
  display: none;
}

input,
textarea,
select,
button {
  font-weight: 500;
  font-family: $font-family-sans-serif;
}

input::placeholder {
  color: $color-blue-gray-200;
}

button {
  transform: scale(1);
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out,
    border 0.2s ease-in-out;

  &:active {
    transform: scale(0.97);
  }
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}
