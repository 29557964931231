@import 'styles/variables.scss';
@import 'styles/animations.scss';

.title {
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.07em;
  margin-bottom: 0.8rem;
  padding-left: 0.4rem;
  text-transform: uppercase;
}

.createSection {
  margin-top: 1em;
  &.noMargin {
    margin-top: 0;
  }
}

.createSectionLabel {
  display: block;
  position: relative;
}

.createInput {
  display: block;
  position: relative;
  z-index: 1;

  appearance: none;
  background: #fff;

  border-radius: $input-border-radius;
  border: 1px solid $color-blue-gray-100;

  font-size: $input-font-size;
  margin: 0;
  height: $input-height;
  line-height: $input-height;
  padding: 0 1.25rem;
  width: 100%;

  &:focus {
    border-color: $color-blue;
    outline: none;
  }
}

.plusButton {
  animation: scale-fade-in 0.15s;

  position: absolute;
  right: 0.5rem;
  top: 50%;
  margin-top: -0.875rem;
  z-index: 2;

  background: $color-lime-1;
  border: none;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1;
  height: 1.75rem;
  width: 1.75rem;

  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.loading {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  margin-top: -0.5rem;
  height: 1rem;

  display: flex;
  align-items: center;
  text-align: center;
  z-index: 2;
}

.error {
  animation: subtle-drop 0.15s;

  font-size: 0.8rem;
  color: red;
  font-weight: 500;
  margin: 0.5rem 0.5rem 0;
}
