@import 'styles/mixins.scss';
@import 'styles/variables.scss';

$green: #aede2a;

.userMessageResponse {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.userMessageResponseCheckmark {
  margin-left: 1.2em;
  position: relative;
  vertical-align: middle;
  height: 0.9em;
  width: 0.9em;
  min-width: 0.9em;
  background: transparent;

  path {
    fill: darken($green, 7.5%);
    fill: #fff;
  }
}

.select {
  cursor: pointer;
  position: absolute;
  z-index: 2;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  font-size: 1em;
  opacity: 0;
}

.dropdownIcon {
  display: block;
  float: right;
  width: 0.7em;
  height: 0.85em;
  margin-left: 0.5em;
  position: relative;
  right: -0.5em;
  top: 0;

  &::after,
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    border-left: 0.32em solid transparent;
    border-right: 0.32em solid transparent;
  }

  &::before {
    top: 0px;
    border-bottom: 0.32em solid #fff;
  }

  &::after {
    bottom: -1px;
    border-top: 0.32em solid #fff;
  }
}
