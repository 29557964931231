@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.loadingWrapper {
  align-items: center;
  display: flex;
  height: calc(100vh - #{$header-height-mobile});
  width: 100vw;

  @include breakpoint($breakpoint-medium) {
    height: calc(100vh - #{$header-height});
  }
}
