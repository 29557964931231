*,
*::before,
*::after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
