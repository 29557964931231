@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.actionButton {
  background: linear-gradient(to bottom, #0ba4ef, #026dd9);
  border: none;
  border-radius: 2em;
  box-shadow: $box-shadow-1;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  font-size: calc(15 / 16 * 1rem);

  padding: 0.7em 1.5em;
  text-shadow: 0 -1px 0 rgba(#000, 0.1);
  text-decoration: none;
  user-select: none;
  vertical-align: top;
  text-align: center;

  margin-bottom: 0.5em;

  & + & {
    margin-top: 0;
  }

  &.selected {
    background: linear-gradient(to bottom, darken($color-lime-1, 5%), darken($color-lime-1, 10%));
    pointer-events: none;
  }

  &.inactive {
    background: linear-gradient(to bottom, $color-blue-gray-100, darken($color-blue-gray-100, 10%));

    &:hover {
      background: linear-gradient(to bottom, #0ba4ef, #026dd9);
    }
  }

  &.disabled {
    background: linear-gradient(to bottom, $color-blue-gray-100, darken($color-blue-gray-100, 10%));

    &:hover {
      transform: none;
      filter: none;
      box-shadow: $box-shadow-1;
    }
  }

  @include breakpoint($breakpoint-mobile) {
    font-size: calc(14 / 16 * 1rem);
    padding: 0.8em 1.5em 0.85em;

    & + & {
      margin: 0 0 0.5em 0.5em;
    }
  }

  @include breakpoint($breakpoint-medium) {
    &:hover {
      box-shadow: $box-shadow-2;
      filter: saturate(1.2);
      transform: translate3d(0, -0.15rem, 0);
    }
  }

  // Mobile
  @media (max-width: $breakpoint-mobile) and (orientation: portrait) {
    padding: 0.8em 1.5em;
    width: calc(100vw - 7.4rem);
  }

  // Interaction
  transform: scale(1);
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.2s ease-in-out,
    border 0.2s ease-in-out, background 0.2s ease-in-out;

  &:active {
    transform: scale(0.97);
  }
}
