@import './variables.scss';
@import './mixins.scss';

h1 {
  font-size: calc(28 / 16 * 1rem);
  font-weight: 700;
}

h2 {
  font-size: calc(21 / 16 * 1rem);
  font-weight: 500;
}
