@keyframes fadeInFromAbove {
  0% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scale-fade-in {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes subtle-drop {
  0% {
    opacity: 0;
    transform: translate3d(0, -0.25rem, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
