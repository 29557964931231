@import 'styles/variables.scss';
@import 'styles/animations.scss';

.title {
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.07em;
  margin-bottom: 0.8rem;
  padding-left: 0.4rem;
  text-transform: uppercase;
}

.pageSearch {
  margin-top: 1em;
}

.noMargin {
  margin: 0;
}

.pageSearchLabel {
  display: block;
  position: relative;

  .searchIcon {
    color: $color-blue-gray-100;
    position: absolute;
    top: 1.05em;
    height: 15px;
    width: 15px;
    z-index: 2;

    left: 1em;
  }
}

.searchInput {
  display: block;

  appearance: none;
  background: #fff;

  border-radius: $input-border-radius;
  border: 1px solid $color-blue-gray-100;

  font-size: $input-font-size;
  margin: 0;
  height: $input-height;
  line-height: $input-height;
  padding: 0 1em 0 2.8em;
  width: 100%;

  &:focus {
    border-color: $color-blue;
    outline: none;
  }
}

.loading {
  padding: 1em 0;
  display: block;
  text-align: center;
  position: absolute;
  right: 1em;
}

.menu {
  border-radius: 0.25em;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  margin: 0.5em 0 0;
  padding: 0;

  max-width: 400px;
  min-width: 0 !important;

  text-align: left;
  overflow: auto;
  z-index: 9;
  background: transparent;
}

.menuItem {
  padding: 0 1em;
  min-height: 3.4em;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  font-weight: 500;
  background: #fff;

  &.active {
    background: $color-blue-gray-25;
  }

  &.disabled {
    cursor: not-allowed;

    &.active {
      background: inherit;
    }

    .itemName,
    .itemImage {
      opacity: 0.5;
    }
  }

  .itemImage {
    float: left;
    margin-right: 1em;
    border-radius: 50%;
    background-color: $color-blue-gray-50;
    border: 2px solid #fff;
    height: 2.4em;
    width: 2.4em;
    min-width: 2.4em;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.itemName {
  font-size: calc(14 / 16 * 1rem);
  font-weight: 600;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itemDescription {
  color: $color-blue-gray-500;
  font-size: calc(12 / 16 * 1rem);
  font-weight: 500;
}

.additionalInfo {
  flex: 1 0 5rem;
  text-align: right;
  color: $color-blue-gray-900;
  font-size: calc(12 / 16 * 1rem);
}

.emptyResultMenu {
  box-shadow: none;
  border-radius: 0;
}

.emptyResult {
  max-width: 400px;
  padding: 0.7rem 0 0;
  font-size: 0.9em;
  line-height: 1.4;

  animation: fade-in 0.15s;

  display: flex;
  align-items: center;

  .infoIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    background: $color-blue-gray-100;
    color: #fff;
    font-family: monospace, serif;
    font-size: 1.75rem;
    font-weight: 600;

    border-radius: 50%;
    margin-right: 1rem;
    height: 2.8rem;
    width: 2.8rem;
  }
}
