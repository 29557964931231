@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.targeFilterSelection {
  padding-bottom: 3rem;
  position: relative;
}

.title {
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.07em;
  margin-bottom: 0.8rem;
  padding-left: 0.4rem;
  text-transform: uppercase;
}

@keyframes fadeInFromBottom {
  0% {
    opacity: 0;
    transform: translateY(0.3rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.inputWrapper {
  position: relative;
  animation: fadeInFromBottom 0.3s;
}

.loading {
  padding: 1em 0;
  display: block;
  text-align: center;
  position: absolute;
  right: 1em;
  top: 0;
}

.input {
  appearance: none;
  background: #fff;
  // border-radius: $input-border-radius;
  border-radius: 30px;
  border: 1px solid $color-blue-gray-100;
  font-size: calc(15 / 16 * 1rem);
  height: $input-height;
  margin: 0;
  line-height: $input-height;
  padding: 0 1rem;
  width: 100%;

  @include breakpoint($breakpoint-mobile) {
    max-width: 40rem;
    min-width: 18rem;
    padding-left: 2.5rem;
  }

  &:focus {
    border-color: $brand-primary;
    outline: none;
  }
}

.searchIcon {
  position: absolute;
  left: 0.75rem;
  top: 1rem;
  z-index: 9;

  color: $color-blue-gray-200;
  width: 1rem;
  height: auto;

  transition: color 0.075s;

  &.valid {
    color: $color-blue;
  }

  // hide on mobile
  display: none;
  @include breakpoint($breakpoint-mobile) {
    display: block;
  }
}

.inputCancelButton {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 3rem;
  text-align: center;
  z-index: 9;
  background: transparent;
  border: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-blue-gray-200;

  &:hover {
    color: $color-blue-gray-400;
  }
}

.cancelIcon {
  width: 1.2rem;
  height: 1.2rem;
}

.loading {
  padding: 1em 0;
  display: block;
  text-align: center;
  position: absolute;
  right: 1em;
  top: 0;
}

.instruction {
  color: $color-blue-gray-300;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}

.inputValidationError {
  animation: fade-in 0.15s;

  color: $color-pink;
  letter-spacing: 0;

  font-size: 0.6rem;
  margin: 0.2rem 0 -0.2rem 1;
  float: none;
  display: block;

  @include breakpoint($breakpoint-mobile) {
    display: inline;
    float: right;
    font-size: inherit;
    margin: 0 0 0 1rem;
  }
}

// # Locale search result
.localeSearchResults {
  position: relative;
  // left: 0;
  // right: 0;
  // top: $input-height + 0.5rem;

  animation: subtle-drop 0.4s;
  border: 1px solid $color-blue-gray-50;
  border-radius: 0.25rem;
  background: #fff;
}

.localeSearchResult {
  background: transparent;
  align-items: center;
  border: none;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  min-height: 3.4em;
  padding: 0 1em;
  text-align: left;
  position: relative;

  width: 100%;
  font-size: 1rem;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:hover {
    background: $color-blue-gray-25;
  }

  &:focus {
    border-radius: 0.25rem;
    box-shadow: 0 0 0 1px $color-blue;
  }
}

.localeInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.localeName {
  font-size: calc(14 / 16 * 1rem);
  font-weight: 600;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emptyResult {
  max-width: 400px;
  padding: 0.7rem;
  margin-bottom: 1.25rem;
  font-size: 0.9em;
  line-height: 1.4;

  animation: fade-in 0.25s;

  display: flex;
  align-items: center;
  position: relative;

  .infoIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    background: $color-blue-gray-100;
    color: #fff;
    font-family: monospace, serif;
    font-size: 1.75rem;
    font-weight: 600;

    border-radius: 50%;
    margin-right: 1rem;
    height: 2.8rem;
    width: 2.8rem;
  }
}

.plusSign {
  display: block;
  text-align: center;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  margin-right: 0.5rem;

  background: $color-lime-1;
  border: none;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  font-weight: bold;
  line-height: 1;
  height: 1.75rem;
  width: 1.75rem;

  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.plusSignIcon {
  width: 0.9rem;
  height: 0.9rem;
}

.secondaryAction {
  position: absolute;
  right: 0rem;
  bottom: -2px;

  button {
    background: transparent;
    color: $color-blue;
    border: none;
    font-size: 0.9rem;
    padding: 0 0.4rem;
    cursor: pointer;
    text-align: right;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

// # Target audience filters selection

.typeSelections {
  padding: 0.5rem 0 1rem;
  min-height: 8.5rem;
}

.typeSelectionButton {
  display: block;
  width: 100%;
  text-align: left;
  // color: $color-blue;
  background: transparent;
  border: none;
  font-size: 0.85rem;
  font-weight: 600;
  cursor: pointer;
  padding: 0.75rem 2em 0.75rem 1rem;
  height: 3rem;
  border: 2px solid $color-blue-gray-50;
  border-radius: 30px;
  position: relative;

  .plusSign {
    right: 0.25rem;
    left: auto;
    top: 0.5rem;
  }

  margin-bottom: 0.5rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  width: 100%;

  @include breakpoint(30rem) {
    width: auto;
    max-width: 25rem;
  }
}

.tag {
  border-radius: 0.3rem;
  color: $color-blue-gray-600;
  border: 2px solid $color-blue-gray-50;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 0.4rem 0.4rem;
  padding: 0.75em 2.25em 0.75em 0.75em;
  position: relative;

  animation: fadeInFromAbove 0.3s;

  button {
    background: transparent;
    width: 1.5rem;
    position: absolute;
    border: none;
    right: 0rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-blue-gray-300;
    margin-left: 0.5rem;
    font-size: 155%;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}

// # Searching

.search {
  position: relative;
  z-index: 3;

  form {
    margin-bottom: 0.5rem;
  }
}

.searchBackground {
  position: absolute;
  z-index: 2;
  left: -15px;
  right: -15px;
  top: -15px;
  bottom: -5px;
}
