@import 'styles/mixins.scss';
@import 'styles/variables.scss';

$green: #aede2a;

.userMessageResponse {
  background: linear-gradient(to bottom, darken($green, 5%), darken($green, 10%));
  border-radius: 0.75rem 0.2rem 0.75rem 0.75rem;
  box-shadow: 0 0.2rem 1.5rem rgba(#000, 0.08);
  color: #fff;
  font-weight: 600;

  padding: 0.65em 1.1em;
  position: relative;
  text-shadow: 0 -1px 0 rgba(#000, 0.1);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint($breakpoint-medium) {
    font-size: 1em;
    padding: 0.75em 1.5em 0.8em;
    border-radius: 1rem 0.2rem 1rem 1rem;
  }

  .tagResponses & {
    display: inline-block;
    margin: 0.4rem 0 0 0.4rem;
  }
}

.responseImage {
  float: left;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: -0.5em 0 -0.5em 0;
}

.userMessageResponseCheckmark {
  // filter: drop-shadow(0 -1px 0 $color-blue-gray-100);
  position: relative;
  vertical-align: middle;
  height: 0.9em;
  width: 0.9em;

  background: transparent;

  path {
    fill: #fff;
  }
}
