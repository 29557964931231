@import 'styles/variables.scss';

.title {
  color: $color-blue-gray-900;
  font-weight: 600;
  margin-bottom: 0.2rem;
}

.tip {
  color: $color-blue-gray-700;
  font-weight: 500;
  font-size: 0.9em;

  .tipTitle {
    font-weight: 600;
  }
}
