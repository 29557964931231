@import 'styles/variables.scss';
@import 'styles/mixins.scss';

$botSpeechBubbleBgColor: #fff;

.botMessageContent {
  background: $botSpeechBubbleBgColor;
  border-radius: 0.2rem 0.75rem 0.75rem 0.75rem;
  box-shadow: $box-shadow-1;
  color: $color-blue-gray-900;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 0.5rem;
  padding: 0.7em 1em;
  position: relative;

  @include breakpoint($breakpoint-medium) {
    padding: 1em 1.5em;
    border-radius: 0.2rem 1rem 1rem 1rem;
  }

  strong {
    font-weight: 600;
  }

  a {
    font-weight: 500;
    color: $color-blue;
  }

  i {
    font-weight: 500;
    color: $color-blue-gray-900;
    font-style: normal;
  }

  button {
    font-weight: 500;
    color: $color-blue;
    text-decoration: underline;
    padding: 0;
    font-size: 1em;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
