@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.title {
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  letter-spacing: 0.07em;
  margin-bottom: 0.8rem;
  padding-left: 0.4rem;
  text-transform: uppercase;
}

.userSearch {
  margin-top: 1em;
}

.inputWrapper {
  position: relative;
}

.loading {
  padding: 1em 0;
  display: block;
  text-align: center;
  position: absolute;
  right: 1em;
  top: 0;
}

.input {
  appearance: none;
  background: #fff;
  border-radius: $input-border-radius;
  border: 1px solid $color-blue-gray-100;
  font-size: calc(15 / 16 * 1rem);
  height: $input-height;
  margin: 0 0 0.5rem;
  line-height: $input-height;
  padding: 0 1rem;

  width: 100%;
  @include breakpoint($breakpoint-mobile) {
    max-width: 40rem;
    min-width: 18rem;
    padding-left: 2.5rem;
  }

  &:focus {
    border-color: $brand-primary;
    outline: none;
  }
}

.linkIcon {
  position: absolute;
  left: 0.75rem;
  top: 1rem;
  z-index: 9;

  color: $color-blue-gray-200;
  width: 1rem;
  height: auto;

  transition: color 0.075s;

  &.valid {
    color: $color-blue;
  }

  // hide on mobile
  display: none;
  @include breakpoint($breakpoint-mobile) {
    display: block;
  }
}

.instruction {
  color: $color-blue-gray-300;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}

.inputValidationError {
  animation: fade-in 0.15s;

  color: $color-pink;
  letter-spacing: 0;

  font-size: 0.6rem;
  margin: 0.2rem 0 -0.2rem 1;
  float: none;
  display: block;

  @include breakpoint($breakpoint-mobile) {
    display: inline;
    float: right;
    font-size: inherit;
    margin: 0 0 0 1rem;
  }
}

// # User search result
.userSearchResult {
  animation: subtle-drop 0.4s;

  align-items: center;
  background: #fff;
  cursor: pointer;
  display: flex;
  font-weight: 500;
  min-height: 3.4em;
  padding: 0 0.5em;
  text-align: left;
  position: relative;

  border: 1px solid $color-blue-gray-50;
  border-radius: 0.25rem;
  width: 100%;
  font-size: 1rem;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:hover {
    background: $color-blue-gray-25;
  }
}

.userImage {
  float: left;
  margin-right: 1em;
  border-radius: 50%;
  background-color: $color-blue-gray-50;
  border: 2px solid #fff;
  height: 2.4em;
  width: 2.4em;
  min-width: 2.4em;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.userName {
  font-size: calc(14 / 16 * 1rem);
  font-weight: 600;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.userDescription {
  color: $color-blue-gray-500;
  font-size: calc(12 / 16 * 1rem);
  font-weight: 500;
}

.emptyResult {
  max-width: 400px;
  padding: 0.7rem 0 0;
  font-size: 0.9em;
  line-height: 1.4;

  animation: fade-in 0.15s;

  display: flex;
  align-items: center;

  .infoIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    background: $color-blue-gray-100;
    color: #fff;
    font-family: monospace, serif;
    font-size: 1.75rem;
    font-weight: 600;

    border-radius: 50%;
    margin-right: 1rem;
    height: 2.8rem;
    width: 2.8rem;
  }
}

.plusSign {
  display: block;
  text-align: center;
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  margin-right: 0.5rem;

  background: $color-lime-1;
  border: none;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  font-weight: bold;
  line-height: 1;
  height: 1.75rem;
  width: 1.75rem;

  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.plusSignIcon {
  width: 0.9rem;
  height: 0.9rem;
}
