@import 'styles/variables.scss';
@import 'styles/animations.scss';
@import 'styles/mixins.scss';

.wrapper {
  position: relative;
}

.input {
  appearance: none;
  background: #fff;
  border-radius: $input-border-radius;
  border: 1px solid $color-blue-gray-100;
  font-size: calc(15 / 16 * 1rem);
  height: $input-height;
  margin: 0 0 0.5rem;
  line-height: $input-height;
  padding: 0 1rem;

  width: 100%;
  @include breakpoint($breakpoint-mobile) {
    max-width: 40rem;
    min-width: 30rem;
    padding-left: 2.5rem;
  }

  &:focus {
    border-color: $brand-primary;
    outline: none;
  }
}

.linkIcon {
  position: absolute;
  left: 0.75rem;
  top: 1rem;
  z-index: 9;

  color: $color-blue-gray-200;
  width: 1rem;
  height: auto;

  transition: color 0.075s;

  &.valid {
    color: $color-blue;
  }

  // hide on mobile
  display: none;
  @include breakpoint($breakpoint-mobile) {
    display: block;
  }
}

.instruction {
  color: $color-blue-gray-300;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0 0.5rem;
}

.inputValidationError {
  animation: fade-in 0.15s;

  color: $color-pink;
  letter-spacing: 0;

  font-size: 0.6rem;
  margin: 0.2rem 0 -0.2rem;
  float: none;
  display: block;

  @include breakpoint($breakpoint-mobile) {
    display: inline;
    float: right;
    font-size: inherit;
    margin: 0;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;

  @include breakpoint($breakpoint-mobile) {
    flex-direction: row;
    margin-top: 1rem;
  }
}

.submitErrorMessage {
  animation: fade-in 0.15s;

  color: $color-pink;

  font-size: 0.8rem;
  font-weight: 600;
  display: block;
  text-align: right;
  padding: 0.5em 0 0.5em;

  @include breakpoint($breakpoint-mobile) {
    max-width: 30rem;
  }
}

.loading {
  animation: fade-in 0.15s;
  padding: 0.5em 1em 0.3em 0;
}

.actionButton {
  animation: subtle-drop 0.15s;

  background: blue;
  border-radius: 1.8rem;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: calc(13 / 16 * 1rem);
  font-weight: 600;
  height: 2rem;
  letter-spacing: 0.01rem;
  line-height: 2rem;
  margin: 0.5rem 0 0;
  padding: 0 1rem;
  user-select: none;

  // responsive
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 15rem;

  @include breakpoint($breakpoint-mobile) {
    display: inline-block;
    width: auto;
    max-width: 20rem;
    margin-top: 0;
    & + & {
      margin-left: 0.75rem;
    }
  }

  &:hover {
    background: darken(blue, 3%);
  }

  .highlight {
    font-weight: bold;
  }
}
