@import 'styles/variables.scss';

.option {
  background: #fff;
  border: 2px solid $color-blue-gray-50;
  border-radius: 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;

  font-size: calc(14 / 16 * 1rem);
  text-align: left;
  width: 100%;
  font-weight: 600;
  padding: 0 0.5rem;
  user-select: none;
  transition: border 0.075s, transform 0.15s ease-in-out;
  cursor: pointer;

  & + & {
    margin: 0.5em 0 0 0;
  }

  .logo {
    background-color: $color-blue-gray-25;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50%;

    display: inline-block;
    margin-right: 0.9rem;
    height: 3rem;
    width: 3rem;
  }

  &.noImageOption {
    min-height: 2.75rem;
    padding-left: 1.25rem;
  }

  &:hover {
    border-color: $color-lime-1;
  }
}

.leftCol {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.optionInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.description {
  color: $color-blue-gray-500;
  font-size: calc(13 / 16 * 1rem);
  font-weight: 500;
  margin-top: calc(2 / 16 * 1rem);
}

.optionLink {
  color: $color-blue-gray-500;
  font-size: calc(12 / 16 * 1rem);
  font-weight: 500;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.optionLinkIcon {
  width: 0.9rem;
  height: 0.9rem;
  margin-left: 0.2rem;
  float: right;
}

.plusSign {
  background: $color-lime-1;
  border: none;
  border-radius: 50%;
  margin-right: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;

  font-weight: bold;
  line-height: 1;
  height: 1.75rem;
  width: 1.75rem;

  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.plusSignIcon {
  width: 0.9rem;
  height: 0.9rem;
}
