@import 'styles/animations.scss';
@import 'styles/variables.scss';
@import 'styles/mixins.scss';

.conversation {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 0 2rem;
  width: $content-container-width;

  transition: transform 0.25s cubic-bezier(0.77, 0, 0.175, 1);

  &.dodgeMessenger {
    @media (min-width: 80rem) and (max-width: 108rem) {
      transform: translate3d(-11rem, 0, 0);
    }
  }

  // Add extra space for chat button
  @include breakpoint($breakpoint-medium, 'max') {
    padding-bottom: 6rem;
  }
}

.conversationWrapper {
  padding: 2rem 0.5rem 0;
  @include breakpoint($breakpoint-medium) {
    padding-top: 6rem;
  }
}

.conversationPart {
  transition: opacity 0.6s;
}

.pastConversation {
  position: relative;
  pointer-events: none;
  user-select: none;
  opacity: 0.35;

  // layer on top of content
  &:after {
    content: '';
    display: block;
    position: absolute;
    overflow: hidden;
    left: -1em;
    right: -1em;
    top: 0;
    bottom: 0;
    z-index: 9;
  }
}

.columns {
  display: flex;

  .textColumn {
    @include breakpoint($breakpoint-small, 'min') {
      flex: 50% 0 0;
      width: 50%;
      padding-right: 1rem;
    }
  }
}

@keyframes change-slide-1 {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }
  26% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes change-slide-2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  26% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes change-slide-3 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  76% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes change-slide-4 {
  0% {
    opacity: 1;
  }

  1% {
    opacity: 0;
  }

  75% {
    opacity: 0;
  }
  76% {
    opacity: 1;
  }
  99% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.embedsMockups {
  display: block;

  flex: 50% 0 0;
  width: 50%;
  position: relative;
  right: -0.7rem;
  margin-top: -0.5rem;
  opacity: 0;
  transform: translate3d(0, -0.25rem, 0);

  animation: subtle-drop 0.5s;
  animation-fill-mode: forwards;
  min-height: 200px;
  overflow: hidden;

  @include breakpoint($breakpoint-medium) {
    min-height: 230px;
    right: -0.9rem;
  }

  @include breakpoint($breakpoint-small, 'max') {
    display: none;
  }

  img {
    width: 100%;
    display: block;
    position: absolute;
    right: 0;
    top: 0;

    animation-duration: 14s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;

    &:nth-child(1) {
      animation-name: change-slide-1;
    }
    &:nth-child(2) {
      animation-name: change-slide-2;
    }
    &:nth-child(3) {
      animation-name: change-slide-3;
    }
    &:nth-child(4) {
      animation-name: change-slide-4;
    }

    /*
    opacity: 0;
    transform: translate3d(0, -0.25rem, 0);

    animation: subtle-drop 0.5s;
    animation-fill-mode: forwards;

    &:nth-child(1) {
      animation-delay: 0.1s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      margin-top: -2px;
      animation-delay: 0.3s;
    }

    &:nth-child(4) {
      margin-top: -2px;
      animation-delay: 0.4s;
    }
    */
  }
}

.secondaryActionNotLinkLike {
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.stateLoadWaiter {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: $color-blue-gray-15;
  z-index: 99;
}

fl-icon {
  display: inline-block;
  margin: -2px;
  position: relative;
  top: 4px;
  vertical-align: middle;

  fl-button & {
    line-height: 0;
    margin: -4px;
    top: 1px;
  }
}
