@import 'styles/animations.scss';
@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.wrapper {
  position: relative;
}

.wordInputField {
  appearance: none;
  background: #fff;
  border-radius: $input-border-radius;
  border: 1px solid $color-blue-gray-100;
  font-size: $input-font-size;
  height: $input-height;
  margin: 0 0 0.5rem;
  line-height: $input-height;
  padding: 0 2.2em 0 1em;

  &:focus {
    border-color: $brand-primary;
    outline: none;
  }
}

.wordInputFieldHidden {
  .wordInputField {
    display: none;
  }
}

.wordInputTags {
  transition: height 0.3s ease-in-out;
}

.wordInputTag {
  background: white;
  border-radius: 0.3rem;
  border: 2px solid $color-neutral-light;
  color: $color-neutral-darker;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1;
  margin: 0 0 0.4rem 0.4rem;
  padding: 0.35em 2em 0.45em .75em;
  position: relative;
}

.wordInputTagRemove {
  border-radius: 100%;
  color: currentColor;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  line-height: 1.5em;
  opacity: 0.7;
  position: absolute;
  top: 0.05em;
  right: 0.35em;
  text-align: center;
  width: 1.5em;
  height: 1.5em;
  background: transparent;
  border: none;
  font-weight: bold;

  &:hover {
    background: rgba(#fff, 0.2);
    opacity: 1;
  }
}

.wordInputButton {
  border: 0;
  background: blue;
  color: #fff;
  padding: 0 2em;
  height: 2rem;
  line-height: 2rem;
  border-radius: 1.8rem;
  font-size: calc(12 / 16 * 1rem);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.07em;

  cursor: pointer;
  user-select: none;
}

.addButton {
  position: absolute;
  z-index: 2;
  right: 0.7rem;
  top: 0.75rem;
  cursor: pointer;
  outline: none;

  background: $color-lime-1;
  width: 1.4rem;
  height: 1.4rem;
  padding: 0;
  border: none;
  font-size: 1em;
  border-radius: 50%;
  font-weight: bold;

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  animation: scale-fade-in 0.2s;
  transition: all 0.05s;

  &.dodgeTags {
    top: 3rem;
  }

  &:hover {
    background: $color-lime-2;
  }
}

.errorMessage {
  color: $color-error-dark;
  font-size: calc(13 / 16 * 1rem);
  font-weight: 600;
}
