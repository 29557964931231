@import 'styles/variables.scss';

$disabled-color: $color-blue-gray-300;
$selected-color: $color-lime-1;

.options {
  margin: 0 0 1.5em;
}

.option {
  padding: 0.5em 0.35em;
  margin: 0.5em 0;

  display: flex;
  flex-direction: row;
  align-items: center;

  // button ux
  user-select: none;
  cursor: pointer;

  &:hover {
    .checkbox {
      border-color: $selected-color;
    }
  }

  &.selected {
    .checkbox {
      border-color: $selected-color;
      background: $selected-color;

      &:before {
        padding-top: 1px;
        content: '✓';
      }
    }
  }

  &.disabled:hover {
    cursor: default;
    .checkbox {
      border-color: $disabled-color;
      background: $disabled-color;
    }
  }

  &.hidden {
    display: none;
  }
}

.checkbox {
  border: 2px solid #ddd;
  border-radius: 3px;
  color: #fff;
  font-size: 1.2rem;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  font-weight: bold;

  display: flex;
  align-items: center;
  justify-content: center;

  // small adjustment
  position: relative;
  top: -1px;

  transition: all 0.05s linear;
}

.label {
  font-weight: 500;
}
